import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { Button, TextField } from '@nexds/web'

import { UniqueEntityID } from '@/domain/core'
import { CoordWaypoint } from '@/domain/models'
import { Coordinates } from '@/domain/protocols/Coordinates'

import { useI18n } from '@/presentation/hooks/useI18n'
import { DMSToDecimal } from '@/utils/coordinates'
import { ToastUtil } from '@/utils/toastUtil'

import { Container, Controls, Example, TextFields } from './UserWaypointManagementScreen.styles'

interface UserWaypointManagementScreenProps {
  action: 'create' | 'update'
  handleCreateUserWaypoint: (waypoint: CoordWaypoint) => Promise<void>
  handleDeleteUserWaypoint: (id: string) => Promise<void>
  handleShowUserWaypointOnMap: (waypoint: CoordWaypoint) => void
  handleUpdateUserWaypoint: (waypoint: CoordWaypoint, waypointId: string) => Promise<void>
}

function UserWaypointManagementScreen(props: UserWaypointManagementScreenProps) {
  const {
    action,
    handleCreateUserWaypoint,
    handleDeleteUserWaypoint,
    handleShowUserWaypointOnMap,
    handleUpdateUserWaypoint
  } = props

  const { t } = useI18n()
  const params = useParams()
  const navigate = useNavigate()

  const [id, setId] = useState('')
  const [name, setName] = useState('')
  const [coordinates, setCoordinates] = useState('')
  const [error, setError] = useState('')

  const [isEditing, setIsEditing] = useState(false)
  const [edited, setEdited] = useState(false)

  useEffect(() => {
    if (action === 'update') {
      params['id'] && setId(params['id'])
      params['name'] && setName(params['name'])

      const urlParams = new URLSearchParams(window.location.search)
      const coordinates = urlParams.get('coords')
      coordinates && setCoordinates(coordinates)
    }
  }, [])

  const handleCopy = () => {
    try {
      navigator.clipboard
        .writeText(coordinates)
        .then(() => {
          ToastUtil.send({
            label: t('TOAST_COORDINATES_COPY_SUCCESS'),
            variant: 'primary'
          })
        })
        .catch(() => {
          ToastUtil.send({
            label: t('TOAST_COORDINATES_COPY_ERROR'),
            variant: 'error'
          })
        })
    } catch (error) {
      console.error(error)
      ToastUtil.send({
        label: t('TOAST_COORDINATES_COPY_ERROR'),
        variant: 'error'
      })
    }
  }

  const validateAndTransformCoordinates = useCallback(
    (value: string) => {
      const checkCoordinates = value ?? coordinates

      const checkRE =
        /^[0-9]{2}\s*[º°]?\s*[0-5][0-9]\s*["']?\s*[0-5][0-9]\s*["']?\s*[NnSs]\s*\/?,?-?\s*[0-1][0-9]{2}\s*[º°]?\s*[0-5][0-9]\s*["']?\s*[0-5][0-9]\s*["']?\s*[LlOoEeWw]\s*$/gi
      const matchCheck = checkRE.exec(checkCoordinates)
      if (!matchCheck) {
        return { valid: false, coordinates: null }
      }

      const latlngRE =
        /^([0-9]{1,2})\s*[º°]?\s*([0-5]{0,1}[0-9]{0,1})?\s*["']?\s*([0-5]{0,1}[0-9]{0,1})?\s*["']?\s*([NnSs])?\s*\/?,?-?\s*([0-9]{1,3})\s*[º°]?\s*([0-5]{0,1}[0-9]{0,1})?\s*["']?\s*([0-5]{0,1}[0-9]{0,1})?\s*["']?\s*([LlOoEeWw])?\s*$/gi
      const latlngMatch = latlngRE.exec(checkCoordinates)
      if (!latlngMatch) {
        return { valid: false, coordinates: null }
      }
      const coordinateConverted = DMSToDecimal(latlngMatch)

      if (
        coordinateConverted.latitude < -90 ||
        coordinateConverted.latitude > 90 ||
        coordinateConverted.longitude < -180 ||
        coordinateConverted.longitude > 180
      ) {
        return { valid: false, coordinates: null }
      }

      return { valid: true, coordinates: coordinateConverted }
    },
    [coordinates]
  )

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setName(e.target.value)
    setIsEditing(true)
    setEdited(true)
  }

  const handleCoordinatesChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setCoordinates(e.target.value)
    setIsEditing(true)
    setEdited(true)
  }

  return (
    <>
      <Container>
        <TextFields>
          <TextField
            label={t('DRAWER_SETTINGS_USER-WAYPOINTS_WAYPOINT_NAME')}
            placeholder={t('DRAWER_SETTINGS_USER-WAYPOINTS_WAYPOINT_NAME-PLACEHOLDER')}
            helpGutter={false}
            value={name}
            onChange={handleNameChange}
            onBlur={() => setIsEditing(false)}
          />
          <TextField
            label={t('DRAWER_SETTINGS_USER-WAYPOINTS_WAYPOINT_COORDS')}
            rightIcon={coordinates ? 'Copy' : undefined}
            onRightIconClick={handleCopy}
            placeholder={t('DRAWER_SETTINGS_USER-WAYPOINTS_WAYPOINT_COORDS-PLACEHOLDER')}
            value={coordinates}
            helpGutter={!!error}
            error={!!error}
            helpMessage={error}
            onChange={handleCoordinatesChange}
            onBlur={() => setIsEditing(false)}
          />
          <Example>{t('DRAWER_SETTINGS_USER-WAYPOINTS_WAYPOINT_COORDS_EXAMPLE')}</Example>
        </TextFields>
        <Controls>
          {action === 'create' && (
            <>
              <Button
                color="secondary"
                size="sm"
                label={t('DRAWER_SETTINGS_USER-WAYPOINTS_WAYPOINT_SAVE')}
                variant="outline"
                disabled={name === '' || coordinates === ''}
                onPress={async () => {
                  const _coordinates = validateAndTransformCoordinates(coordinates)

                  if (!_coordinates.valid) {
                    setError(t('DRAWER_SETTINGS_USER-WAYPOINTS_WAYPOINT_COORDS-INVALID'))
                    return
                  }

                  if (_coordinates.valid) {
                    setError('')
                    await handleCreateUserWaypoint(
                      CoordWaypoint.create(
                        {
                          customName: name,
                          coordinates: _coordinates.coordinates
                        },
                        {}
                      )
                    )

                    navigate(-1)
                  }
                }}
              />
              <Button
                color="danger"
                size="sm"
                label={t('DRAWER_SETTINGS_USER-WAYPOINTS_WAYPOINT_CANCEL')}
                variant="ghost"
                onPress={() => {
                  navigate(-1)
                }}
              />
            </>
          )}
          {action === 'update' && !isEditing && !edited ? (
            <>
              <Button
                leftIcon="PointOnMap"
                color="secondary"
                size="sm"
                label={t('DRAWER_SETTINGS_USER-WAYPOINTS_WAYPOINT_SHOW-ON-MAP')}
                variant="outline"
                onPress={() => {
                  handleShowUserWaypointOnMap(
                    CoordWaypoint.create(
                      {
                        customName: name,
                        coordinates: new Coordinates({
                          latitude: validateAndTransformCoordinates(coordinates).coordinates.latitude,
                          longitude: validateAndTransformCoordinates(coordinates).coordinates.longitude
                        })
                      },
                      {},
                      new UniqueEntityID(id)
                    )
                  )
                }}
              />
              <Button
                leftIcon="Trash"
                color="danger"
                size="sm"
                label={t('DRAWER_SETTINGS_USER-WAYPOINTS_WAYPOINT_DELETE')}
                variant="ghost"
                onPress={async () => {
                  await handleDeleteUserWaypoint(id)
                  navigate(-1)
                }}
              />
            </>
          ) : (
            action === 'update' &&
            (isEditing || edited) && (
              <>
                <Button
                  color="secondary"
                  size="sm"
                  label={t('DRAWER_SETTINGS_USER-WAYPOINTS_WAYPOINT_SAVE')}
                  variant="outline"
                  disabled={name === '' || coordinates === ''}
                  onPress={async () => {
                    const _coordinates = validateAndTransformCoordinates(coordinates)

                    if (!_coordinates.valid) {
                      setError(t('DRAWER_SETTINGS_USER-WAYPOINTS_WAYPOINT_COORDS-INVALID'))
                      return
                    }

                    if (_coordinates.valid) {
                      setError('')
                      await handleUpdateUserWaypoint(
                        CoordWaypoint.create(
                          {
                            customName: name,
                            coordinates: _coordinates.coordinates
                          },
                          {}
                        ),
                        id
                      )

                      navigate(-1)
                    }
                  }}
                />
                <Button
                  color="danger"
                  size="sm"
                  label={t('DRAWER_SETTINGS_USER-WAYPOINTS_WAYPOINT_CANCEL')}
                  variant="ghost"
                  onPress={() => {
                    navigate(-1)
                  }}
                />
              </>
            )
          )}
        </Controls>
      </Container>
    </>
  )
}

export { UserWaypointManagementScreen }
