import { CSSProperties } from 'react'

import styled from 'styled-components'

export const returnButtonStyle: CSSProperties = {
  position: 'absolute'
}

export const VNavScreenContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`

export const InputButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

export const Label = styled.label`
  ${({ theme }) => theme.fonts.labelSM}
  color: ${({ theme }) => theme.colors.neutralL5};
`

interface InputButtonProps {
  isSet: boolean
}

export const InputButton = styled.button<InputButtonProps>`
  border: none;
  background-color: ${({ theme }) => theme.colors.secondary};
  ${({ theme }) => theme.fonts.infoLG}
  color: ${({ theme, isSet }) => (isSet ? theme.colors.neutralL5 : theme.colors.secondaryL3)};
  height: 40px;
  width: 100%;
  border-radius: 10px;
  padding: 12px 16px;
  text-align: start;
  cursor: pointer;
`

export const CruiseAltitudeDetails = styled.div`
  margin-top: 8px;
`

export const Value = styled.span`
  ${({ theme }) => theme.fonts.infoMD}
  color: ${({ theme }) => theme.colors.neutralL5};
`
export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
`

export const Title = styled.h1`
  ${({ theme }) => theme.fonts.infoLG}
  text-transform: uppercase;
  text-align: center;
  justify-self: flex-start;
  padding: 20px 13px;
  width: 100%;
`

export const Body = styled.div`
  margin-top: 8px;
  width: calc(100% + 9px);
  padding-right: 9px;

  overflow-y: auto;

  &::-webkit-scrollbar {
    border-radius: 20px;
    width: 4px;
    margin-right: 8px;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
    border-radius: 20px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.colors.secondaryL2};
    border-radius: 20px;
  }
`
