import styled from 'styled-components'

export const CruiseAltitudeItemContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
  border-radius: 10px;
  min-height: 48px;
  height: 48px;
  padding: 0 14px;
  background-color: ${({ theme }) => theme.colors.secondary};
`

export const CruiseAltitudeItemInfosContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex: 1;
  max-width: 220px;
`

interface CruiseAltitudeItemInfoProps {
  type?: 'altitude'
}

export const CruiseAltitudeItemInfo = styled.div<CruiseAltitudeItemInfoProps>`
  ${({ theme, type }) => {
    if (type === 'altitude') {
      return theme.fonts.h5
    } else {
      return theme.fonts.infoLG
    }
  }}
  color: ${({ theme, type }) => (type === 'altitude' ? theme.colors.neutralL4 : theme.colors.neutralL3)};
  flex: 1;
  text-align: ${({ type }) => (type === 'altitude' ? 'left' : 'center')};
`

export const RadioContainer = styled.div``
