import styled from 'styled-components'

import { getStateColor } from '@/presentation/utils'

export const AircraftContainer = styled.div`
  position: relative;
`

export const AircraftActionsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 7px;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
`

export const ActionButton = styled.button`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.secondaryL1};
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: ${({ theme }) => getStateColor(theme.colors.secondaryL1, 'hover')};
  }

  &:active {
    background-color: ${({ theme }) => getStateColor(theme.colors.secondaryL1, 'pressed')};
  }
`
