import { Radio } from '@nexds/web'

import { convertSecondsToHrsMinsOrSecs } from '@/utils/date'
import { isSet } from '@/utils/validation'

import {
  CruiseAltitudeItemContainer,
  CruiseAltitudeItemInfosContainer,
  CruiseAltitudeItemInfo,
  RadioContainer
} from './CruiseAltitudeItem.styles'

interface CruiseAltitudeItemProps {
  cruiseAltitude: number
  operationalCeilingUnit: string
  eteInSeconds: number
  fuelConsumptionInLitersPerHour: number
  cruiseAltitudeInDomainUnit: number
  activeCruiseAltitude: number
  radioName: string
  selected: boolean
  onSelect: () => void
}

function CruiseAltitudeItem(props: CruiseAltitudeItemProps) {
  const {
    cruiseAltitude,
    operationalCeilingUnit,
    eteInSeconds,
    fuelConsumptionInLitersPerHour,
    cruiseAltitudeInDomainUnit,
    activeCruiseAltitude,
    radioName,
    selected,
    onSelect
  } = props
  return (
    <CruiseAltitudeItemContainer onClick={onSelect}>
      <CruiseAltitudeItemInfosContainer>
        <CruiseAltitudeItemInfo type="altitude">
          {cruiseAltitude} {operationalCeilingUnit}
        </CruiseAltitudeItemInfo>
        <CruiseAltitudeItemInfo>
          {isSet(eteInSeconds) ? convertSecondsToHrsMinsOrSecs(eteInSeconds) : '--:--'}
        </CruiseAltitudeItemInfo>
        <CruiseAltitudeItemInfo>
          {isSet(fuelConsumptionInLitersPerHour) ? fuelConsumptionInLitersPerHour.toFixed(0) : '--'}{' '}
          {/* {activeAircraft.fuelConsumptionUnit.replace('/h', '')} */}
          {'L'}
        </CruiseAltitudeItemInfo>
      </CruiseAltitudeItemInfosContainer>
      <RadioContainer>
        <Radio onSelect={onSelect} value={cruiseAltitudeInDomainUnit.toString()} name={radioName} checked={selected} />
      </RadioContainer>
    </CruiseAltitudeItemContainer>
  )
}

export { CruiseAltitudeItem }
