import { ChangeEvent, KeyboardEventHandler, useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { Assets, Checkbox, IconButton, Switch, TextField } from '@nexds/web'
import { useTheme } from 'styled-components'

import { CoordWaypoint } from '@/domain/models'

import { useI18n } from '@/presentation/hooks/useI18n'
import { convertDecimalDegreesToDMS } from '@/utils/coordinates'

import { UserWaypoint } from '../UserWaypoint/UserWaypoint'
import {
  ControlsContainer,
  SearchContainer,
  MassImportOrDeleteContainer,
  Header,
  Container,
  MassImportButton,
  WaypointsContainer,
  MessageContainer,
  Message,
  ActionButton,
  ScrollableContainer
} from './UserWaypointsScreen.styles'

interface UserWaypointsScreenProps {
  waypoints: CoordWaypoint[]
  handleBatchDeleteWaypoints: (waypoints: CoordWaypoint[]) => void
  handleExportWaypoints: (waypoints: CoordWaypoint[]) => void
  handleImportWaypoints: (file: File) => void
  searchInputValue: string
  setSearchInputValue: (value: string) => void
}

function UserWaypointsScreen(props: UserWaypointsScreenProps) {
  const {
    waypoints,
    handleBatchDeleteWaypoints,
    handleExportWaypoints,
    handleImportWaypoints,
    searchInputValue,
    setSearchInputValue
  } = props

  const theme = useTheme()
  const { t } = useI18n()

  const navigate = useNavigate()

  const [selectChecked, setSelectChecked] = useState(false)
  const [selectedWaypoints, setSelectedWaypoints] = useState<CoordWaypoint[]>([])

  useEffect(() => {
    setSelectChecked(selectedWaypoints.length > 0)
  }, [selectedWaypoints])

  useEffect(() => {
    setSelectedWaypoints([])
  }, [waypoints])

  const handleSelectChange = useCallback(() => {
    setSelectedWaypoints((prev: CoordWaypoint[]) => {
      if (prev.length === waypoints.length) return []
      else if (prev.length === 0) return waypoints
      else return []
    })
  }, [waypoints])

  const handleSearchChange = useCallback((e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setSearchInputValue(e.target.value)
  }, [])

  const handleSearchKeyDown = useCallback<KeyboardEventHandler<HTMLInputElement>>((e) => {
    if (e.key === 'Escape') {
      setSearchInputValue('')
      e.currentTarget.blur()
    }
  }, [])

  const handleUserWaypointChange = useCallback((waypoint: CoordWaypoint) => {
    setSelectedWaypoints((prev) => {
      if (prev.includes(waypoint)) return prev.filter((w) => w.id !== waypoint.id)
      return [...prev, waypoint]
    })
  }, [])

  const selectWaypointsLabel = useCallback(() => {
    return selectedWaypoints.length > 0
      ? `${
          selectedWaypoints.length === waypoints.length
            ? t('DRAWER_SETTINGS_USER-WAYPOINTS_DESELECT_ALL')
            : `${t('DRAWER_SETTINGS_USER-WAYPOINTS_DESELECT')} (${selectedWaypoints.length})`
        }`
      : t('DRAWER_SETTINGS_USER-WAYPOINTS_SELECT-ALL')
  }, [selectedWaypoints, waypoints])

  const handleImportButtonClick = useCallback(() => {
    const input = document.createElement('input')
    input.type = 'file'
    input.accept = '.csv,.kml'
    input.onchange = (e) => {
      const files = (e.target as HTMLInputElement).files
      if (files && files.length > 0) {
        handleImportWaypoints(files[0])
      }
    }
    input.click()
    input.value = null

    setSelectedWaypoints([])
  }, [])

  return (
    <Container>
      <Header>
        <SearchContainer>
          <TextField
            leftIcon={'Search'}
            placeholder={t('DRAWER_SETTINGS_USER-WAYPOINTS_SEARCH-PLACEHOLDER')}
            onChange={handleSearchChange}
            onKeyDown={handleSearchKeyDown}
            value={searchInputValue}
          />
          <IconButton
            icon="Plus"
            onClick={() => {
              navigate('create')
            }}
            color={'primary'}
            size={'sm'}
          />
        </SearchContainer>
        {waypoints && waypoints.length > 0 && (
          <ControlsContainer>
            <Checkbox
              label={selectWaypointsLabel()}
              checkedStyle={'deselect'}
              checked={selectChecked}
              onChange={handleSelectChange}
            />
            <MassImportOrDeleteContainer>
              <ActionButton
                onClick={() => {
                  handleExportWaypoints(selectedWaypoints)
                  setSelectedWaypoints([])
                }}
                disabled={selectedWaypoints.length === 0}
              >
                <Assets.Download size={22} color={theme.colors.neutralL5} />
              </ActionButton>
              <ActionButton
                onClick={() => {
                  handleBatchDeleteWaypoints(selectedWaypoints)
                  setSelectedWaypoints([])
                }}
                disabled={selectedWaypoints.length === 0}
              >
                <Assets.Trash size={22} color={theme.colors.error} />
              </ActionButton>
            </MassImportOrDeleteContainer>
          </ControlsContainer>
        )}
      </Header>
      {waypoints && waypoints.length > 0 ? (
        <ScrollableContainer>
          <WaypointsContainer>
            {waypoints.map((waypoint, place) => (
              <UserWaypoint
                key={waypoint.id.toString()}
                waypointsLength={waypoints.length}
                place={place}
                waypoint={waypoint}
                checked={selectedWaypoints.includes(waypoint)}
                onChange={() => handleUserWaypointChange(waypoint)}
                onClick={() => {
                  const coords = convertDecimalDegreesToDMS(
                    waypoint.coordinates.latitude,
                    waypoint.coordinates.longitude
                  )
                  const coordsParam = encodeURIComponent(coords)
                  navigate(`edit/${waypoint?.customName}/${waypoint?.id}?coords=${coordsParam}`)
                }}
              />
            ))}
          </WaypointsContainer>
        </ScrollableContainer>
      ) : (
        <MessageContainer>
          {searchInputValue.length > 0 ? (
            <Message>{t('DRAWER_SETTINGS_USER-WAYPOINTS_NO-WAYPOINTS')}</Message>
          ) : (
            <Message>{t('DRAWER_SETTINGS_USER-WAYPOINTS_CLICK-TO-CREATE')}</Message>
          )}
        </MessageContainer>
      )}

      <MassImportButton onClick={handleImportButtonClick}>
        {t('DRAWER_SETTINGS_USER-WAYPOINTS_IMPORT')}
      </MassImportButton>
    </Container>
  )
}

export { UserWaypointsScreen }
