import { CSSProperties } from 'react'

import styled from 'styled-components'

import { getStateColor } from '@/presentation/utils'

export const returnButtonStyle: CSSProperties = {
  position: 'absolute'
}

export const AircraftRegisterScreenContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
`

export const Title = styled.h1`
  ${({ theme }) => theme.fonts.infoLG}
  text-transform: uppercase;
  text-align: center;
  justify-self: flex-start;
  padding: 20px 13px;
  width: 100%;
`

export const TabContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
`

interface TabProps {
  active: boolean
}

export const TabItem = styled.button<TabProps>`
  flex: 1;
  cursor: pointer;

  border: 1px solid ${({ theme, active }) => (active ? 'transparent' : theme.colors.secondaryL1)};
  color: ${({ theme, active }) => (active ? theme.colors.primaryL2 : theme.colors.neutralL5)};
  background-color: ${({ theme, active }) => (active ? `${theme.colors.primary}33` : theme.colors.secondaryD1)};

  border-radius: 50px;
  padding: 10px 20px;
  text-transform: uppercase;
  ${({ theme }) => theme.fonts.titleXS}

  &:hover {
    background-color: ${({ theme, active }) =>
      active ? getStateColor(`${theme.colors.primaryD2}33`, 'hover') : theme.colors.secondaryD1};
  }

  &:active {
    background-color: ${({ theme, active }) =>
      active ? getStateColor(`${theme.colors.primaryD2}33`, 'pressed') : theme.colors.secondaryD1};
  }
`

export const Scrollable = styled.div`
  margin-top: 16px;
  width: calc(100% + 9px);
  padding-right: 9px;

  overflow-y: auto;

  &::-webkit-scrollbar {
    border-radius: 20px;
    width: 4px;
    margin-right: 8px;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
    border-radius: 20px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.colors.secondaryL2};
    border-radius: 20px;
  }
`
export const Form = styled.form``

export const GeneralTabGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

export const InputGroup = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;
  padding: 20px 8px;
  align-items: end;
`

export const Divider = styled.div`
  height: 1px;
  background-color: ${({ theme }) => theme.colors?.secondaryL1 ?? '#2e3b42'};
  width: 100%;
`

export const ButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  margin: 24px 0;
  padding: 0 48px;
`
