import styled, { css } from 'styled-components'

import { getStateColor } from '@/presentation/utils'

export const Container = styled.div`
  width: 144px;
`

export const Label = styled.p`
  color: ${({ theme }) => theme.colors.neutralL5};
  margin-bottom: 12px;
  text-transform: uppercase;
  ${({ theme }) => theme.fonts.labelMD};
`

export const UnitFieldInputContainer = styled.div`
  cursor: text;
  position: relative;
  background-color: ${({ theme }) => theme.colors.secondary};
  height: 44px;
  border-radius: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 8px;

  & .unit-field-input {
    margin-right: 36px;
    &[type='number']::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }
    &[type='number'] {
      -moz-appearance: textfield;
      appearance: textfield;
    }
  }
`

interface ButtonProps {
  type: 'increment' | 'decrement' | 'switch'
}

export const Button = styled.button<ButtonProps>`
  border: none;
  position: absolute;
  ${({ type }) => (type === 'decrement' ? 'left: 4px;' : 'right: 4px;')}
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 56px;
  min-width: 32px;
  height: 32px;
  border-radius: 8px;
  padding: 9px 4px;
  background-color: ${({ theme }) => theme.colors.secondaryL1};
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => getStateColor(theme.colors.secondaryL1, 'hover')};
  }

  &:active {
    background-color: ${({ theme }) => getStateColor(theme.colors.secondaryL1, 'pressed')};
  }
`

export const ButtonText = styled.p`
  ${({ theme }) => theme.fonts.infoMD};
  color: ${({ theme }) => theme.colors.neutralL5};
`

export const Minus = styled.div`
  height: 2px;
  width: 12px;
  background-color: ${({ theme }) => theme.colors.neutralL5};
  border-radius: 3px;
`

export const StepButton = styled.button`
  border: none;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  flex-wrap: nowrap;
`

export const StepValue = styled.p`
  ${({ theme }) => theme.fonts.infoMD};
  color: ${({ theme }) => theme.colors.neutralL5};
`

export const StepUnit = styled.p`
  ${({ theme }) => theme.fonts.infoMD};
  color: ${({ theme }) => theme.colors.neutralL5};
  text-decoration: underline;
  margin-left: 2px;
`
