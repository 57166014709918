import { Dispatch, SetStateAction, useCallback, useMemo, useRef, useState } from 'react'

import { Assets, TextField } from '@nexds/web'

import { BaseUnit, Unit } from '@/domain/protocols/Unit'

import {
  Container,
  Label,
  UnitFieldInputContainer,
  ButtonText,
  Button,
  StepUnit,
  StepButton,
  StepValue,
  Minus
} from './UnitField.styles'

export const baseUnitsMapper: Record<BaseUnit, Unit[]> = {
  speed: ['kt', 'km/h', 'mph'],
  direction: ['°M', '°T'],
  altitude: ['ft', 'm'],
  rate: ['ft/min', 'm/s'],
  fuel: ['L/h', 'kg', 'lb', 'IMP gal', 'US gal']
}

export interface UnitFieldProps {
  baseUnit: BaseUnit
  currentUnit?: Unit
  initialUnit?: Unit
  label: string
  steps?: number
  type: 'switch' | 'steps'
  value?: number
  onChangeStep?: (value: number) => void
  onChangeValue?: (value: number) => void
  onChangeUnit?: Dispatch<SetStateAction<Unit>>
}

function UnitField(props: UnitFieldProps) {
  const {
    baseUnit,
    label,
    steps,
    type = 'switch',
    initialUnit,
    value,
    currentUnit,
    onChangeStep,
    onChangeValue,
    onChangeUnit
  } = props

  const inputRef = useRef<HTMLInputElement>(null)

  const initialIndex = baseUnitsMapper[baseUnit].findIndex((unit) =>
    currentUnit ? currentUnit === unit : unit === initialUnit
  )
  const [unitIndex, setUnitIndex] = useState(initialIndex !== -1 ? initialIndex : 0)

  const handleSwitchUnit = useCallback(() => {
    const nextUnitIndex = unitIndex + 1 < baseUnitsMapper[baseUnit].length ? unitIndex + 1 : 0
    setUnitIndex(nextUnitIndex)
    onChangeUnit?.(baseUnitsMapper[baseUnit][nextUnitIndex])
  }, [unitIndex, baseUnitsMapper, baseUnit, onChangeUnit])

  const handleIncrement = useCallback(() => {
    onChangeStep?.(value + steps)
  }, [onChangeStep, value, steps])

  const handleDecrement = useCallback(() => {
    onChangeStep?.(value - steps)
  }, [onChangeStep, value, steps])

  return (
    <Container>
      <Label>{label}</Label>
      <UnitFieldInputContainer onClick={() => inputRef.current?.focus()}>
        {type === 'steps' && (
          <Button type="decrement" onClick={handleDecrement}>
            <Minus />
          </Button>
        )}
        {type === 'switch' ? (
          <TextField
            className="unit-field-input"
            ref={inputRef}
            labelGutter={false}
            helpGutter={false}
            size="sm"
            value={value?.toString() ?? '0'}
            onChange={(event) => onChangeValue?.(Number(event.target.value))}
            type="number"
          />
        ) : (
          <StepButton onClick={handleSwitchUnit}>
            <StepValue>{value}</StepValue>
            <StepUnit>{baseUnitsMapper[baseUnit][unitIndex]}</StepUnit>
          </StepButton>
        )}
        {type === 'steps' ? (
          <Button type="increment" onClick={handleIncrement}>
            <Assets.Plus color="#FCFCFC" size={'sm'} />
          </Button>
        ) : (
          <Button type="switch" onClick={handleSwitchUnit}>
            <ButtonText>{currentUnit ?? baseUnitsMapper[baseUnit][unitIndex]}</ButtonText>
          </Button>
        )}
      </UnitFieldInputContainer>
    </Container>
  )
}

export { UnitField }
