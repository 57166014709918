import { useCallback, useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { InjectionTokens } from '@/controller/tokens'

import { CoordWaypoint } from '@/domain/models'

import { IUserWaypointsRepository } from '@/data/UserWaypointsRepository/IUserWaypointsRepository'

import { useInjection } from '@/presentation/contexts/InjectionContext'
import { useBehaviorSubject } from '@/presentation/hooks/useBehaviorSubject'
import { useI18n } from '@/presentation/hooks/useI18n'
import { ModalUtil } from '@/utils/modalUtil'
import { ToastUtil } from '@/utils/toastUtil'

import { LoadingStateMutator } from '../../Base/states/LoadingState'
import { MapImperativeInterface } from '../../MapScreen/interfaces/MapImperativeInterface'
import { MapTargetStateActions } from '../../MapScreen/states/MapTargetState'
import { ScreenElementsState, ScreenElementsStateProps } from '../../MapScreen/states/ScreenElementsState'
import { UserWaypointManagementScreen } from '../components/UserWaypointManagementScreen/UserWaypointManagementScreen'
import { UserWaypointsScreen } from '../components/UserWaypointsScreen/UserWaypointsScreen'

function UserWaypointsScreenPresenter() {
  const appLocation = useLocation()
  const navigate = useNavigate()

  const { t } = useI18n()

  const userWaypointsRepository = useInjection<IUserWaypointsRepository>(InjectionTokens.UserWaypointsRepository)
  const screenElementsState = useBehaviorSubject<ScreenElementsStateProps>(ScreenElementsState)

  const [userWaypoints, setUserWaypoints] = useState<CoordWaypoint[]>([])
  const [searchInputValue, setSearchInputValue] = useState('')
  const [filteredWaypoints, setFilteredWaypoints] = useState<CoordWaypoint[]>([])

  const getUserWaypoints = useCallback(() => {
    const result = userWaypointsRepository.getWaypoints()
    if (result.isSuccess) {
      setUserWaypoints(result.getValue())
    }
  }, [userWaypointsRepository])

  useEffect(() => {
    getUserWaypoints()
  }, [])

  useEffect(() => {
    setFilteredWaypoints(
      searchInputValue === ''
        ? userWaypoints.sort((a, b) => b.updatedAt.getTime() - a.updatedAt.getTime())
        : userWaypoints
            .filter((item) => item.customName.toLowerCase().includes(searchInputValue.toLowerCase()))
            .sort((a, b) => b.updatedAt.getTime() - a.updatedAt.getTime())
    )
  }, [userWaypoints, searchInputValue])

  const handleCreateUserWaypoint = useCallback(
    async (waypoint: CoordWaypoint) => {
      LoadingStateMutator.setIsLoading(true)
      const result = await userWaypointsRepository.saveWaypoint(waypoint)
      if (!result.isSuccess || result.isFailure) {
        ToastUtil.send({
          label: t('TOAST_USER-WAYPOINT_CREATE_ERROR'),
          dismissible: true,
          variant: 'error'
        })
      } else {
        ToastUtil.send({
          label: t('TOAST_USER-WAYPOINT_CREATE_SUCCESS'),
          dismissible: true,
          variant: 'primary'
        })
      }
      getUserWaypoints()
      LoadingStateMutator.setIsLoading(false)
    },
    [userWaypointsRepository]
  )

  const handleDeleteUserWaypoint = useCallback(
    async (id: string) => {
      const deleteWaypoint = async () => {
        LoadingStateMutator.setIsLoading(true)
        const result = await userWaypointsRepository.deleteWaypoint(id)
        if (result.isSuccess || !result.isFailure) {
          ToastUtil.send({
            label: t('TOAST_USER-WAYPOINT_DELETE_ERROR'),
            dismissible: true,
            variant: 'error'
          })
        } else {
          ToastUtil.send({
            label: t('TOAST_USER-WAYPOINT_DELETE_SUCCESS'),
            dismissible: true,
            variant: 'error'
          })
        }
        getUserWaypoints()
        LoadingStateMutator.setIsLoading(false)
      }
      ModalUtil.drawer.show({
        onPositive: deleteWaypoint,
        onNegative: () => {},
        positiveColor: 'danger',
        positiveLabel: t('DRAWER_SETTINGS_USER-WAYPOINTS_WAYPOINT_DELETE'),
        negativeLabel: t('DRAWER_SETTINGS_USER-WAYPOINTS_WAYPOINT_GO-BACK'),
        title: t('DRAWER_SETTINGS_USER-WAYPOINTS_WAYPOINT_DELETE-MODAL-TITLE'),
        headerDescription: t('DRAWER_SETTINGS_USER-WAYPOINTS_WAYPOINT_DELETE-MODAL-TEXT')
      })
    },
    [userWaypointsRepository]
  )

  const handleUpdateUserWaypoint = useCallback(
    async (waypoint: CoordWaypoint, waypointId: string) => {
      LoadingStateMutator.setIsLoading(true)
      const result = await userWaypointsRepository.updateWaypoint(waypoint, waypointId) // TODO: Trocar para um useCase

      if (result.isSuccess || !result.isFailure) {
        ToastUtil.send({
          label: t('TOAST_USER-WAYPOINT_UPDATE_ERROR'),
          dismissible: true,
          variant: 'error'
        })
      } else {
        ToastUtil.send({
          label: t('TOAST_USER-WAYPOINT_UPDATE_SUCCESS'),
          dismissible: true,
          variant: 'primary'
        })
      }
      getUserWaypoints()
      LoadingStateMutator.setIsLoading(false)
    },
    [userWaypointsRepository]
  )

  const handleShowUserWaypointOnMap = useCallback((waypoint: CoordWaypoint) => {
    MapTargetStateActions.setTarget(waypoint.coordinates)
    const padding: [number, number, number, number] = [
      40 + screenElementsState.boundsDefaultPadding.top,
      75 + screenElementsState.boundsDefaultPadding.right,
      40 + screenElementsState.boundsDefaultPadding.bottom,
      75 + screenElementsState.boundsDefaultPadding.left
    ]
    MapImperativeInterface.flyToCoordinates(waypoint.coordinates, 11, 2000, padding)
  }, [])

  const handleBatchDeleteWaypoints = useCallback(
    async (waypoints: CoordWaypoint[]) => {
      const deleteWaypoints = async () => {
        const result = await userWaypointsRepository.deleteWaypoints(waypoints) // TODO: Trocar para um useCase
        console.log(result)
        if (!result.isSuccess || result.isFailure) {
          ToastUtil.send({
            label: t('TOAST_USER-WAYPOINT_BATCH-DELETE_ERROR'),
            dismissible: true,
            variant: 'error'
          })
        } else {
          ToastUtil.send({
            label: t('TOAST_USER-WAYPOINT_BATCH-DELETE_SUCCESS'),
            dismissible: true,
            variant: 'error'
          })
        }
      }
      ModalUtil.drawer.show({
        onPositive: async () => {
          LoadingStateMutator.setIsLoading(true)
          await deleteWaypoints()
          getUserWaypoints()
          LoadingStateMutator.setIsLoading(false)
        },
        onNegative: () => {},
        positiveColor: 'danger',
        positiveLabel: t('DRAWER_SETTINGS_USER-WAYPOINTS_WAYPOINT_DELETE') + 's (' + waypoints.length + ')', // TODO: i18n -> colocar no arquivo de i18n
        negativeLabel: t('DRAWER_SETTINGS_USER-WAYPOINTS_WAYPOINT_GO-BACK'),
        title: t('DRAWER_SETTINGS_USER-WAYPOINTS_WAYPOINT_DELETE-MODAL-TITLE'),
        headerDescription: t('DRAWER_SETTINGS_USER-WAYPOINTS_WAYPOINT_DELETE-MODAL-TEXT')
      })
    },
    [userWaypointsRepository]
  )

  const handleExportWaypoints = useCallback(
    async (waypoints: CoordWaypoint[]) => {
      const result = await userWaypointsRepository.exportWaypoints(waypoints) // TODO: Trocar para um useCase

      if (result.isSuccess) {
        const url = window.URL.createObjectURL(new Blob([result.getValue()]))
        const link = document.createElement('a')
        link.href = url
        const date = new Date()
          .toLocaleString('pt-BR', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit'
          })
          .replace(/\//g, '-')
          .replace(',', '')
          .replace(/:/g, '-')

        link.setAttribute('download', `waypoints-${date}.csv`)
        document.body.appendChild(link)
        link.click()
      }
    },
    [userWaypointsRepository]
  )

  const handleImportWaypoints = useCallback(
    async (file: File) => {
      LoadingStateMutator.setIsLoading(true)
      const result = await userWaypointsRepository.importWaypoints(file) // TODO: Trocar para um useCase
      console.log(result)
      if (!result.isSuccess || result.isFailure) {
        ToastUtil.send({
          label: t('TOAST_USER-WAYPOINT_IMPORT_ERROR'),
          dismissible: true,
          variant: 'error'
        })
      } else if (!result.getValue().isAllValid) {
        ToastUtil.send({
          label: t('TOAST_USER-WAYPOINT_IMPORT_SOME-NOT-IMPORTED'),
          dismissible: true,
          variant: 'secondary',
          leftIcon: 'Warning',
          timeDelay: 5000
        })
      } else {
        ToastUtil.send({
          label: t('TOAST_USER-WAYPOINT_IMPORT_SUCCESS'),
          dismissible: true,
          variant: 'primary'
        })
      }
      getUserWaypoints()
      LoadingStateMutator.setIsLoading(false)
    },
    [userWaypointsRepository]
  )

  return (
    <>
      {appLocation.pathname === '/settings/user-waypoints' ? (
        <UserWaypointsScreen
          waypoints={filteredWaypoints}
          handleBatchDeleteWaypoints={handleBatchDeleteWaypoints}
          handleExportWaypoints={handleExportWaypoints}
          handleImportWaypoints={handleImportWaypoints}
          setSearchInputValue={setSearchInputValue}
          searchInputValue={searchInputValue}
        />
      ) : appLocation.pathname === '/settings/user-waypoints/create' ? (
        <UserWaypointManagementScreen
          action="create"
          handleCreateUserWaypoint={handleCreateUserWaypoint}
          handleDeleteUserWaypoint={handleDeleteUserWaypoint}
          handleShowUserWaypointOnMap={handleShowUserWaypointOnMap}
          handleUpdateUserWaypoint={handleUpdateUserWaypoint}
        />
      ) : (
        appLocation.pathname.startsWith('/settings/user-waypoints/edit') && (
          <UserWaypointManagementScreen
            action="update"
            handleCreateUserWaypoint={handleCreateUserWaypoint}
            handleDeleteUserWaypoint={handleDeleteUserWaypoint}
            handleShowUserWaypointOnMap={handleShowUserWaypointOnMap}
            handleUpdateUserWaypoint={handleUpdateUserWaypoint}
          />
        )
      )}
    </>
  )
}

export { UserWaypointsScreenPresenter }
