import { useState } from 'react'

import { CruiseAltitudeSelector } from '../components/CruiseAltitudeSelector/CruiseAltitudeSelector'
import { VNavScreen } from '../components/VNavScreen/VNavScreen'

function VNavScreenPresenter() {
  const [isSelectingAltitude, setIsSelectingAltitude] = useState(false)
  const [cruiseAltitude, setCruiseAltitude] = useState(500)

  if (isSelectingAltitude) {
    return (
      <CruiseAltitudeSelector
        currentCruiseAltitude={cruiseAltitude}
        onSelectAltitude={(cruiseAltitudeSelected: number) => {
          setCruiseAltitude(cruiseAltitudeSelected)
          setIsSelectingAltitude(false)
        }}
      />
    )
  } else {
    return <VNavScreen cruiseAltitude={cruiseAltitude} onAltitudeButtonClick={() => setIsSelectingAltitude(true)} />
  }
}

export { VNavScreenPresenter }
