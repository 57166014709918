import styled from 'styled-components'

export const MiddleItemContainer = styled.div`
  & * {
    color: ${({ theme }) => theme.colors.neutralL3};
  }

  & svg path {
    stroke: ${({ theme }) => theme.colors.neutralL3};
  }

  & > *:first-child {
    height: 56px;
    background-color: ${({ theme }) => `${theme.colors.secondary}99`};
    border-bottom: 1px solid transparent;
  }
`
