import { CSSProperties } from 'react'

import styled from 'styled-components'

export const FloatButtonStyle: CSSProperties = {
  position: 'fixed',
  bottom: '30px',
  right: '26px',
  borderRadius: '16px',
  zIndex: 10
}

export const AircraftScreenContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`

export const Title = styled.h1`
  ${({ theme }) => theme.fonts.infoLG}
  text-transform: uppercase;
  text-align: center;
  padding: 20px 13px;
  width: 100%;
`

export const SearchContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 16px;
`

export const EmptyList = styled.div`
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
  z-index: -1;
`

export const EmptyMessage = styled.p`
  width: 75%;
  text-align: center;
  ${({ theme }) => theme.fonts.infoMD}
  color: ${({ theme }) => theme.colors.secondaryL3};
`

export const AircraftsList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`
