import { useCallback, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { Assets, IconButton, MenuItem, Radio, TextField } from '@nexds/web'
import { useTheme } from 'styled-components'

import { useI18n } from '@/presentation/hooks/useI18n'
import { RouterPaths } from '@/presentation/router/RouterPathsMapper'

import { AircraftItem } from './AircraftItem/AircraftItem'
import {
  AircraftsList,
  AircraftScreenContainer,
  EmptyList,
  EmptyMessage,
  FloatButtonStyle,
  Title,
  SearchContainer
} from './AircraftScreen.styles'

interface AircraftScreenProps {
  aircrafts: any[]
}

function AircraftScreen(props: AircraftScreenProps) {
  const { aircrafts } = props

  const { t } = useI18n()
  const navigate = useNavigate()
  const theme = useTheme()

  // variable filterValue is not used yet
  const filterValue = useMemo(() => '', [])

  const [selectedAircraft, setSelectedAircraft] = useState(null)

  return (
    <AircraftScreenContainer>
      <Title>{t('DRAWER_AIRCRAFT_MAIN_TITLE')}</Title>
      <SearchContainer>
        <TextField
          placeholder="Pesquisar"
          value={filterValue}
          onChange={(event) => {
            console.log('Filter aircrafts', event.target.value)
          }}
          leftIcon="Search"
          helpGutter={false}
          labelGutter={false}
        />
      </SearchContainer>
      <IconButton
        icon="Plus"
        color="primary"
        radius="square"
        size="lg"
        style={FloatButtonStyle}
        onClick={() => {
          navigate(RouterPaths.AIRCRAFT__CREATE)
        }}
      />
      {aircrafts && aircrafts.length > 0 ? (
        <AircraftsList>
          {aircrafts.map((aircraft) => (
            <AircraftItem
              key={aircraft.id}
              id={aircraft.id}
              name={aircraft.name}
              type={aircraft.type}
              selected={selectedAircraft?.id === aircraft.id}
              onAircraftSelect={() => setSelectedAircraft(aircraft)}
              onEdit={(event) => {
                event.stopPropagation()
                event.preventDefault()
                navigate(`${RouterPaths.AIRCRAFT__EDIT}/${aircraft.id}`)
              }}
              onDelete={(event) => {
                event.stopPropagation()
                event.preventDefault()
                console.log('Delete aircraft', aircraft.id)
              }}
            />
          ))}
        </AircraftsList>
      ) : (
        <EmptyList>
          <EmptyMessage>
            {filterValue && filterValue.length > 0
              ? t('DRAWER_AIRCRAFT_MAIN_LIST_NO-AIRCRAFT')
              : t('DRAWER_AIRCRAFT_MAIN_LIST_NO-AIRCRAFT')}
          </EmptyMessage>
        </EmptyList>
      )}
    </AircraftScreenContainer>
  )
}

export { AircraftScreen }
