import { Pill } from '@nexds/web'
import styled, { CSSProperties } from 'styled-components'

import { getStateColor } from '@/presentation/utils'

export const returnButtonStyle: CSSProperties = {
  position: 'absolute'
}

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  cursor: pointer;
`

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
`

export const Title = styled.h1`
  ${({ theme }) => theme.fonts.infoLG}
  text-transform: uppercase;
  text-align: center;
  justify-self: flex-start;
  padding: 20px 13px;
  width: 100%;
`

export const FilterContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 12px;
  width: 100%;
  margin: 16px 0;
`

interface FilterPillProps {
  selected: boolean
}

export const FilterPill = styled.span<FilterPillProps>`
  width: auto;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  padding: 8px 10px;
  background-color: ${({ theme, selected }) => (selected ? theme.colors.neutralL5 : theme.colors.secondary)};
  color: ${({ theme, selected }) => (selected ? theme.colors.secondaryD1 : theme.colors.neutralL5)};
  cursor: pointer;
  text-overflow: ellipsis;
  ${({ theme }) => theme.fonts.titleMD}
  overflow: hidden;

  transition: 0.2s;

  &:hover {
    transition: 0.2s;
    background-color: ${({ theme, selected }) =>
      selected ? getStateColor(theme.colors.neutralL5, 'hover') : getStateColor(theme.colors.secondary, 'hover')};
  }

  &:active {
    transition: 0.2s;
    background-color: ${({ theme, selected }) =>
      selected ? getStateColor(theme.colors.neutralL5, 'pressed') : getStateColor(theme.colors.secondary, 'pressed')};
  }
`

export const ActionButton = styled.button`
  border: none;
  cursor: pointer;
  background-color: ${({ theme }) => theme.colors.secondary};
  width: 36px;
  height: 36px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background-color: ${({ theme }) => getStateColor(theme.colors.secondary, 'hover')};
  }

  &:active {
    background-color: ${({ theme }) => getStateColor(theme.colors.secondary, 'pressed')};
  }
`

export const CruiseAltitudeList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 12px;
  padding-bottom: 26px;

  width: calc(100% + 9px);
  padding-right: 9px;

  overflow-y: auto;

  &::-webkit-scrollbar {
    border-radius: 20px;
    width: 4px;
    margin-right: 8px;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
    border-radius: 20px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.colors.secondaryL2};
    border-radius: 20px;
  }
`
