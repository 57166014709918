import { FormEvent, useCallback, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { Button, Combobox, IconButton, Select, SelectItem, TextField } from '@nexds/web'

import {
  AircraftRegisterScreenContainer,
  ButtonsContainer,
  Divider,
  Form,
  GeneralTabGroup,
  InputGroup,
  returnButtonStyle,
  Scrollable,
  TabContainer,
  TabItem,
  Title,
  TitleContainer
} from './AircraftRegisterScreen.styles'
import { UnitField } from './UnitField'

/* TODO: i18n  */
interface AircraftRegisterScreenProps {
  action: 'create' | 'edit'
}

function AircraftRegisterScreen(props: AircraftRegisterScreenProps) {
  const { action } = props
  const navigate = useNavigate()
  const params = useParams()

  const [currentTab, setCurrentTab] = useState('general')

  const onSubmit = useCallback((event: FormEvent) => {
    event.preventDefault()
    console.log('submit')
  }, [])

  const [ceilingValue, setCeilingValue] = useState<number>(0)

  return (
    <AircraftRegisterScreenContainer>
      <TitleContainer>
        <IconButton icon="ChevronLeft" onClick={() => navigate(-1)} size="xs" color="ghost" style={returnButtonStyle} />
        <Title>{action === 'create' ? 'Cadastro' : 'Edição'}</Title>
      </TitleContainer>
      {action === 'edit' && <i>Editando: {params.id}</i>}
      <TabContainer>
        <TabItem active={currentTab === 'general'} onClick={() => setCurrentTab('general')}>
          Geral
        </TabItem>
        <TabItem active={currentTab === 'performance'} onClick={() => setCurrentTab('performance')}>
          Performance
        </TabItem>
      </TabContainer>
      <Scrollable>
        <Form onSubmit={onSubmit}>
          {currentTab === 'general' ? (
            <GeneralTabGroup>
              <TextField label="Nome da aeronave" size="sm" helpGutter={false} />
              <TextField label="Matrícula da aeronave" size="sm" helpGutter={false} />
              <Combobox label="Tipo da aeronave" size="sm">
                <SelectItem value="1" label="Cessna 172" />
                <SelectItem value="2" label="Cessna 182" />
                <SelectItem value="3" label="Cessna 210" />
              </Combobox>
              <Select label="Combustível" size="sm">
                <SelectItem value="1" label="Avgas" />
                <SelectItem value="2" label="Jet A" />
              </Select>
              <ButtonsContainer>
                <Button
                  color="primary"
                  label="Próximo"
                  variant="filled"
                  size="sm"
                  fullWidth
                  onPress={() => setCurrentTab('performance')}
                />
                {action === 'edit' && (
                  <Button color="danger" label="Excluir" variant="ghost" size="sm" fullWidth onPress={() => {}} />
                )}
              </ButtonsContainer>
            </GeneralTabGroup>
          ) : (
            <>
              <InputGroup>
                <UnitField baseUnit="speed" initialUnit="kt" label={'Velocidade de subida'} type="switch" />
                <UnitField baseUnit="speed" initialUnit="ft/min" label={'Razão de subida'} type="switch" />
                <UnitField baseUnit="fuel" initialUnit="L/h" label={'Consumo na subida'} type="switch" />
              </InputGroup>
              <Divider />
              <InputGroup>
                <UnitField baseUnit="speed" initialUnit="kt" label={'Velocidade de cruzeiro'} type="switch" />
                <UnitField baseUnit="fuel" initialUnit="L/h" label={'Consumo em cruzeiro'} type="switch" />
                <UnitField
                  baseUnit="altitude"
                  type="steps"
                  label={'Altitude de cruzeiro'}
                  steps={100}
                  value={ceilingValue}
                  onChangeStep={setCeilingValue}
                />
              </InputGroup>
              <Divider />
              <InputGroup>
                <UnitField baseUnit="speed" initialUnit="kt" label={'Velocidade de descida'} type="switch" />
                <UnitField baseUnit="speed" initialUnit="ft/min" label={'Razão de descida'} type="switch" />
                <UnitField baseUnit="fuel" initialUnit="L/h" label={'Consumo na descida'} type="switch" />
              </InputGroup>
              <Divider />
              <InputGroup>
                <UnitField baseUnit="fuel" initialUnit="L/h" label={'Consumo total para táxi'} type="switch" />
              </InputGroup>
              <ButtonsContainer>
                <Button color="primary" label="Salvar" variant="filled" size="sm" fullWidth onPress={() => {}} />
                {action === 'edit' && (
                  <Button color="danger" label="Excluir" variant="ghost" size="sm" fullWidth onPress={() => {}} />
                )}
              </ButtonsContainer>
            </>
          )}
        </Form>
      </Scrollable>
    </AircraftRegisterScreenContainer>
  )
}

export { AircraftRegisterScreen }
