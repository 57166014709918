import React from 'react'
import { useNavigate } from 'react-router-dom'

import { Assets, MenuItem, Radio } from '@nexds/web'
import { useTheme } from 'styled-components'

import { ActionButton, AircraftActionsContainer, AircraftContainer } from './AircraftItem.styles'

interface AircraftItemProps {
  id: string
  name: string
  type: string
  selected: boolean
  onAircraftSelect: () => void
  onEdit: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  onDelete: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
}

function AircraftItem(props: AircraftItemProps) {
  const { id, name, type, selected, onAircraftSelect, onEdit, onDelete } = props

  const theme = useTheme()

  return (
    <AircraftContainer>
      <MenuItem title={name} description={type} onClick={onAircraftSelect} />
      <AircraftActionsContainer>
        <ActionButton onClick={onDelete} title="Excluir">
          <Assets.Trash size={16} color={theme.colors.neutralL5} />
        </ActionButton>
        <ActionButton onClick={onEdit} title="Editar">
          <Assets.Edit size={16} color={theme.colors.neutralL5} />
        </ActionButton>
        <Radio size="sm" name="aircraft" id={id} checked={selected} onChange={onAircraftSelect} />
      </AircraftActionsContainer>
    </AircraftContainer>
  )
}

export { AircraftItem }
