import { ReactNode } from 'react'

import { MenuItem, MenuItemProps } from '@nexds/web'

import { MiddleItemContainer } from './MiddleItem.styles'

interface MiddleItemProps {
  label: string
  value: ReactNode
  icon?: MenuItemProps['icon']
}

function MiddleItem(props: MiddleItemProps) {
  const { label, value, icon } = props
  return (
    <MiddleItemContainer>
      <MenuItem title={label} rightDecorator={value} icon={icon} />
    </MiddleItemContainer>
  )
}

export { MiddleItem }
