import { format } from 'date-fns-tz'

import { TranslationTokens, VarsByToken } from '@/i18n/ITranslations'

export const formatInUTC = (date: Date, fmt: string) => {
  if (!date) return null
  if (!isFinite(date as any)) return null

  const dateOffset = date.getTimezoneOffset() * 60000
  const fixedDate = new Date(date.getTime() + dateOffset)

  return format(fixedDate, fmt, { timeZone: 'UTC' })
}

export const timeSinceString = (
  date: Date,
  dateFinal: Date = new Date(),
  t: <T extends TranslationTokens>(...args: VarsByToken[T] extends never ? [T] : [T, VarsByToken[T]]) => string,
  short?: boolean,
  hourExtended?: boolean
) => {
  if (!date || !dateFinal) return null

  const seconds = Math.floor((dateFinal.getTime() - date.getTime()) / 1000)

  let interval = Math.floor(seconds / 31536000)
  if (interval >= 1) {
    return `${interval} ${interval > 1 ? t('TIME-UNITS_YEARS') : t('TIME-UNITS_YEAR')}`
  }

  interval = Math.floor(seconds / 2592000)
  if (interval >= 1) {
    return `${interval} ${interval > 1 ? t('TIME-UNITS_MONTHS') : t('TIME-UNITS_MONTH')}`
  }

  interval = Math.floor(seconds / 604800)
  if (interval >= 1) {
    return `${interval}${short ? '' : ' '}${
      short ? t('TIME-UNITS_WEEK_ABBREVIATED') : interval > 1 ? t('TIME-UNITS_WEEKS') : t('TIME-UNITS_WEEK')
    }`
  }

  interval = Math.floor(seconds / 86400)
  if (interval >= 1) {
    return `${interval} ${interval > 1 ? t('TIME-UNITS_DAYS') : t('TIME-UNITS_DAY')}`
  }

  interval = Math.floor(seconds / 3600)
  if (interval >= 1) {
    if (hourExtended) {
      // Show hours and minutes
      const hours = Math.floor(interval)
      const minutes = Math.floor((seconds - hours * 3600) / 60)

      return `${hours}${short ? '' : ' '}${
        short ? t('TIME-UNITS_HOUR_ABBREVIATED') : interval > 1 ? t('TIME-UNITS_HOURS') : t('TIME-UNITS_HOUR')
      } ${t('TIME-UNITS_CONNECTOR')} ${minutes}${short ? '' : ' '}${
        short ? t('TIME-UNITS_MINUTE_ABBREVIATED') : minutes > 1 ? t('TIME-UNITS_MINUTES') : t('TIME-UNITS_MINUTE')
      }`
    }

    return `${interval}${short ? '' : ' '}${
      short ? t('TIME-UNITS_HOUR_ABBREVIATED') : interval > 1 ? t('TIME-UNITS_HOURS') : t('TIME-UNITS_HOUR')
    }`
  }

  interval = Math.floor(seconds / 60)
  if (interval >= 1) {
    return `${interval} ${
      short ? t('TIME-UNITS_MINUTE_ABBREVIATED') : interval > 1 ? t('TIME-UNITS_MINUTES') : t('TIME-UNITS_MINUTE')
    }`
  }

  interval = Math.floor(seconds)

  return `${interval}${short ? '' : ' '}${
    short
      ? t('TIME-UNITS_SECOND_ABBREVIATED')
      : interval > 1 || interval === 0
        ? t('TIME-UNITS_SECONDS')
        : t('TIME-UNITS_SECOND')
  }`
}

export const convertSecondsToHrsMinsOrSecs = (seconds: number) => {
  let timeValueFormatted = ''

  const hours = Math.floor(seconds / 3600)
  const minutes = Math.floor((seconds - hours * 3600) / 60)

  timeValueFormatted = `${hours?.toString().padStart(2, '0')}:${minutes?.toString().padStart(2, '0')}`

  if (hours === 0 && minutes === 0) timeValueFormatted = `${Math.floor(seconds)?.toString().padStart(2, '0')}s`

  return timeValueFormatted
}
