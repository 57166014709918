import { useLocation } from 'react-router-dom'

import { RouterPaths } from '@/presentation/router/RouterPathsMapper'

import { AircraftRegisterScreen } from '../components/AircraftRegisterScreen'
import { AircraftScreen } from '../components/AircraftScreen'

function AircraftScreenPresenter() {
  const appLocation = useLocation()
  const aircrafts = [
    { id: 1, name: 'Aeronave 1', type: 'Cessna 172' },
    { id: 2, name: 'Aeronave 2', type: 'Cessna 182' }
  ]
  return (
    <>
      {appLocation.pathname === RouterPaths.AIRCRAFT ? (
        <AircraftScreen aircrafts={aircrafts} />
      ) : appLocation.pathname === RouterPaths.AIRCRAFT__CREATE ? (
        <AircraftRegisterScreen action="create" />
      ) : appLocation.pathname.startsWith(RouterPaths.AIRCRAFT__EDIT) ? (
        <AircraftRegisterScreen action="edit" />
      ) : null}
    </>
  )
}

export { AircraftScreenPresenter }
