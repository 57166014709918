import { useCallback, useEffect, useState } from 'react'

import { Assets, IconButton, Pill } from '@nexds/web'
import { useTheme } from 'styled-components'

import { CruiseAltitudeItem } from '../CruiseAltitudeItem/CruiseAltitudeItem'
import {
  ActionButton,
  Container,
  CruiseAltitudeList,
  FilterContainer,
  FilterPill,
  returnButtonStyle,
  Title,
  TitleContainer
} from './CruiseAltitudeSelector.styles'

interface CruiseAltitudeSelectorProps {
  currentCruiseAltitude: number
  onSelectAltitude: (cruiseAltitudeSelected: number) => void
}

function CruiseAltitudeSelector(props: CruiseAltitudeSelectorProps) {
  const { currentCruiseAltitude, onSelectAltitude } = props

  const theme = useTheme()

  const [selectedAltitude, setSelectedAltitude] = useState<number | null>(null)
  const [selectedFilters, setSelectedFilters] = useState<string[]>([])

  useEffect(() => {
    setSelectedAltitude(currentCruiseAltitude)
  }, [currentCruiseAltitude])

  const handleFilterClick = useCallback(
    (filter: string) => {
      if (selectedFilters.includes(filter)) {
        setSelectedFilters(selectedFilters.filter((selectedFilter) => selectedFilter !== filter))
      } else {
        setSelectedFilters([...selectedFilters, filter])
      }
    },
    [selectedFilters]
  )

  const handleReturn = useCallback(() => {
    setSelectedAltitude(null)
    onSelectAltitude(currentCruiseAltitude)
  }, [onSelectAltitude])

  return (
    <Container>
      <TitleContainer>
        <IconButton icon="ChevronLeft" onClick={handleReturn} size="xs" color="ghost" style={returnButtonStyle} />
        <Title>Escolha uma altitude </Title>
      </TitleContainer>
      <FilterContainer>
        {selectedFilters.length > 0 && (
          <ActionButton onClick={() => setSelectedFilters([])}>
            <Assets.DeselectFunnel size={16} color={theme.colors.neutralL3} />
          </ActionButton>
        )}
        <FilterPill selected={selectedFilters.includes('VFR')} onClick={() => handleFilterClick('VFR')}>
          VFR
        </FilterPill>
        <FilterPill selected={selectedFilters.includes('IFR')} onClick={() => handleFilterClick('IFR')}>
          IFR
        </FilterPill>
        <FilterPill selected={selectedFilters.includes('IMPAR')} onClick={() => handleFilterClick('IMPAR')}>
          ÍMPAR
        </FilterPill>
        <FilterPill selected={selectedFilters.includes('PAR')} onClick={() => handleFilterClick('PAR')}>
          PAR
        </FilterPill>
      </FilterContainer>
      <CruiseAltitudeList>
        <CruiseAltitudeItem
          cruiseAltitude={500}
          operationalCeilingUnit="ft"
          eteInSeconds={60 * 37}
          fuelConsumptionInLitersPerHour={120}
          cruiseAltitudeInDomainUnit={500}
          activeCruiseAltitude={500}
          radioName="cruiseAltitude"
          selected={selectedAltitude === 500}
          onSelect={() => {
            onSelectAltitude(500)
            setSelectedAltitude(500)
          }}
        />
        <CruiseAltitudeItem
          cruiseAltitude={1000}
          operationalCeilingUnit="ft"
          eteInSeconds={60 * 37}
          fuelConsumptionInLitersPerHour={120}
          cruiseAltitudeInDomainUnit={1000}
          activeCruiseAltitude={500}
          radioName="cruiseAltitude"
          selected={selectedAltitude === 1000}
          onSelect={() => {
            onSelectAltitude(1000)
            setSelectedAltitude(1000)
          }}
        />
        <CruiseAltitudeItem
          cruiseAltitude={1500}
          operationalCeilingUnit="ft"
          eteInSeconds={60 * 37}
          fuelConsumptionInLitersPerHour={120}
          cruiseAltitudeInDomainUnit={1500}
          activeCruiseAltitude={500}
          radioName="cruiseAltitude"
          selected={selectedAltitude === 1500}
          onSelect={() => {
            onSelectAltitude(1500)
            setSelectedAltitude(1500)
          }}
        />
        <CruiseAltitudeItem
          cruiseAltitude={2000}
          operationalCeilingUnit="ft"
          eteInSeconds={60 * 37}
          fuelConsumptionInLitersPerHour={120}
          cruiseAltitudeInDomainUnit={2000}
          activeCruiseAltitude={500}
          radioName="cruiseAltitude"
          selected={selectedAltitude === 2000}
          onSelect={() => {
            onSelectAltitude(2000)
            setSelectedAltitude(2000)
          }}
        />
        <CruiseAltitudeItem
          cruiseAltitude={2500}
          operationalCeilingUnit="ft"
          eteInSeconds={60 * 37}
          fuelConsumptionInLitersPerHour={120}
          cruiseAltitudeInDomainUnit={2500}
          activeCruiseAltitude={500}
          radioName="cruiseAltitude"
          selected={selectedAltitude === 2500}
          onSelect={() => {
            onSelectAltitude(2500)
            setSelectedAltitude(2500)
          }}
        />
        <CruiseAltitudeItem
          cruiseAltitude={3000}
          operationalCeilingUnit="ft"
          eteInSeconds={60 * 37}
          fuelConsumptionInLitersPerHour={120}
          cruiseAltitudeInDomainUnit={3000}
          activeCruiseAltitude={500}
          radioName="cruiseAltitude"
          selected={selectedAltitude === 3000}
          onSelect={() => {
            onSelectAltitude(3000)
            setSelectedAltitude(3000)
          }}
        />
        <CruiseAltitudeItem
          cruiseAltitude={3500}
          operationalCeilingUnit="ft"
          eteInSeconds={60 * 37}
          fuelConsumptionInLitersPerHour={120}
          cruiseAltitudeInDomainUnit={3500}
          activeCruiseAltitude={500}
          radioName="cruiseAltitude"
          selected={selectedAltitude === 3500}
          onSelect={() => {
            onSelectAltitude(3500)
            setSelectedAltitude(3500)
          }}
        />
        <CruiseAltitudeItem
          cruiseAltitude={4000}
          operationalCeilingUnit="ft"
          eteInSeconds={60 * 37}
          fuelConsumptionInLitersPerHour={120}
          cruiseAltitudeInDomainUnit={4000}
          activeCruiseAltitude={500}
          radioName="cruiseAltitude"
          selected={selectedAltitude === 4000}
          onSelect={() => {
            onSelectAltitude(4000)
            setSelectedAltitude(4000)
          }}
        />
        <CruiseAltitudeItem
          cruiseAltitude={4500}
          operationalCeilingUnit="ft"
          eteInSeconds={60 * 37}
          fuelConsumptionInLitersPerHour={120}
          cruiseAltitudeInDomainUnit={4500}
          activeCruiseAltitude={500}
          radioName="cruiseAltitude"
          selected={selectedAltitude === 4500}
          onSelect={() => {
            onSelectAltitude(4500)
            setSelectedAltitude(4500)
          }}
        />
      </CruiseAltitudeList>
    </Container>
  )
}

export { CruiseAltitudeSelector }
