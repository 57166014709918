import { useMemo } from 'react'

import { MenuGroup, MenuItem } from '@nexds/web'

import { InjectionTokens } from '@/controller/tokens'

import { CoordWaypoint } from '@/domain/models'
import { RouteState, RouteStateProps } from '@/domain/states/RouteState'

import { useGlobalState } from '@/presentation/hooks/useGlobalState'

import { MiddleItem } from '../MiddleItem/MiddleItem'
import {
  Body,
  CruiseAltitudeDetails,
  InputButton,
  InputButtonContainer,
  Label,
  Title,
  TitleContainer,
  Value,
  VNavScreenContainer
} from './VNavScreen.styles'

interface VNavScreenProps {
  cruiseAltitude: number
  onAltitudeButtonClick: () => void
}

function VNavScreen(props: VNavScreenProps) {
  const { cruiseAltitude, onAltitudeButtonClick } = props

  const stepValue = useMemo(() => {
    return <Value>{`${cruiseAltitude} ft`}</Value>
  }, [])
  return (
    <VNavScreenContainer>
      <Title>Navegação Vertical</Title>
      <InputButtonContainer>
        <Label>Altitude de Cruzeiro</Label>
        <InputButton isSet={!!cruiseAltitude} onClick={onAltitudeButtonClick}>
          {cruiseAltitude ? `${cruiseAltitude} ft` : 'Selecionar Altitude'}
        </InputButton>
      </InputButtonContainer>
      <Body>
        {cruiseAltitude && (
          <CruiseAltitudeDetails>
            <MenuGroup>
              <MenuItem title="ATIBAIA" rightDecorator={stepValue} />
              <MenuItem title="ATIBAIA" rightDecorator={stepValue} />
              <MiddleItem icon="TOC" label="TOC" value={stepValue} />
              <MenuItem title="ATIBAIA" rightDecorator={stepValue} />
              <MenuItem title="ATIBAIA" rightDecorator={stepValue} />
              <MiddleItem icon="TOD" label="TOD" value={stepValue} />
              <MenuItem title="ATIBAIA" rightDecorator={stepValue} />
            </MenuGroup>
          </CruiseAltitudeDetails>
        )}
      </Body>
    </VNavScreenContainer>
  )
}

export { VNavScreen }
