import { id, inject, injectable } from 'inversify'

import { InjectionTokens } from '@/controller/tokens'

import { UniqueEntityID } from '@/domain/core'
import { CoordWaypoint } from '@/domain/models'
import { Coordinates } from '@/domain/protocols/Coordinates'
import { Result } from '@/domain/protocols/Result'
import { AuthState } from '@/domain/states/AuthState'

import type { IHttpClient } from '@/infra/http/IHttpClient'

import {
  IUserWaypointsRepository,
  RequestGetWaypointsResponse,
  RequestDeleteWaypointsResponse,
  ExportWaypointsResponse
} from './IUserWaypointsRepository'
@injectable()
export class UserWaypointsRepository implements IUserWaypointsRepository {
  private userWaypoints: CoordWaypoint[]
  constructor(
    @inject(InjectionTokens.HttpClient) private httpCLient: IHttpClient,
    @inject(InjectionTokens.AuthState) private authState: AuthState
  ) {}

  protected getRemoteUrl(): string {
    return `${import.meta.env.VITE_NEXATLAS_API_URL}/userWaypoint`
  }

  async init(): Promise<Result<void>> {
    const result = await this._getRemoteWaypoints()
    if (result.isSuccess) this.userWaypoints = result.getValue()

    return Result.ok()
  }

  getWaypoint(waypointId: UniqueEntityID): Result<CoordWaypoint> {
    const waypoint = this.userWaypoints.find((waypoint) => waypoint.id === waypointId)
    if (!waypoint) return Result.fail('Waypoint not found')
    return Result.ok(waypoint)
  }

  getWaypoints(): Result<CoordWaypoint[]> {
    if (this.userWaypoints) return Result.ok(this.userWaypoints)
    else return Result.fail('Waypoints not found')
  }

  private async _getRemoteWaypoints(): Promise<Result<CoordWaypoint[]>> {
    try {
      const response = await this.httpCLient.get<RequestGetWaypointsResponse>(`${this.getRemoteUrl()}/getWaypoints`, {
        headers: {
          Authorization: `Token ${this.authState.getStateSnapshot().token}`
        }
      })

      if (response.success === false) return Result.fail(response.error)

      const waypoints = response.response.waypoints.map((remoteWaypoint) => {
        const { id, name, latitude, longitude, created_at, updated_at } = remoteWaypoint

        return CoordWaypoint.create(
          {
            customName: String(name).trim(),
            coordinates: Coordinates.createFromArray([longitude, latitude]),
            createdAt: new Date(created_at),
            updatedAt: new Date(updated_at)
          },
          {
            isUserWaypoint: true
          },
          new UniqueEntityID(id)
        )
      })

      return Result.ok(waypoints)
    } catch (error) {
      return Result.fail(error)
    }
  }
  async saveWaypoint(waypoint: CoordWaypoint): Promise<Result<void>> {
    try {
      const response = await this.httpCLient.post(
        `${this.getRemoteUrl()}/upsertWaypoint`,
        {
          id: waypoint.id.toString(),
          name: waypoint.customName,
          latitude: waypoint.coordinates.latitude,
          longitude: waypoint.coordinates.longitude
        },
        {
          headers: {
            Authorization: `Token ${this.authState.getStateSnapshot().token}`
          }
        }
      )

      if (response.success === false) return Result.fail(response.error)

      this.userWaypoints = (await this._getRemoteWaypoints()).getValue()

      return Result.ok()
    } catch (error) {
      return Result.fail(error)
    }
  }

  async updateWaypoint(waypoint: CoordWaypoint, waypointId?: string): Promise<Result<UniqueEntityID>> {
    try {
      const response = await this.httpCLient.post(
        `${this.getRemoteUrl()}/upsertWaypoint`,
        {
          id: waypointId ?? waypoint.id.toString(),
          name: waypoint.customName,
          latitude: waypoint.coordinates.latitude,
          longitude: waypoint.coordinates.longitude
        },
        {
          headers: {
            Authorization: `Token ${this.authState.getStateSnapshot().token}`
          }
        }
      )

      if (response.success === false) return Result.fail(response.error)

      this.userWaypoints = (await this._getRemoteWaypoints()).getValue()

      return Result.ok(waypoint.id)
    } catch (error) {
      return Result.fail(error)
    }
  }

  async deleteWaypoint(waypointId: string): Promise<Result<void>> {
    try {
      const response = await this.httpCLient.post<RequestDeleteWaypointsResponse>(
        `${this.getRemoteUrl()}/removeWaypoint`,
        {
          id: waypointId
        },
        {
          headers: {
            Authorization: `Token ${this.authState.getStateSnapshot().token}`
          }
        }
      )

      if (response.success === false) return Result.fail(response.error)

      this.userWaypoints = this.userWaypoints.filter((w) => w.id.toString() !== waypointId)

      return Result.ok()
    } catch (error) {
      return Result.fail(error)
    }
  }

  async deleteWaypoints(waypoints: CoordWaypoint[]): Promise<Result<void>> {
    try {
      const response = await this.httpCLient.post<RequestDeleteWaypointsResponse>(
        `${this.getRemoteUrl()}/removeBatch`,
        {
          ids: waypoints.map((w) => w.id.toString())
        },
        {
          headers: {
            Authorization: `Token ${this.authState.getStateSnapshot().token}`
          }
        }
      )

      if (response.success === false) return Result.fail(response.error)

      this.userWaypoints = this.userWaypoints.filter(
        (w) => !waypoints.some((waypoint) => waypoint.id.toString() === w.id.toString())
      )

      return Result.ok()
    } catch (error) {
      return Result.fail(error)
    }
  }

  async exportWaypoints(waypoints: CoordWaypoint[]): Promise<Result<Blob>> {
    try {
      const response = await this.httpCLient.post<ExportWaypointsResponse>(
        `${this.getRemoteUrl()}/exportFile`,
        {
          ids: waypoints.map((w) => w.id.toString())
        },
        {
          headers: {
            Authorization: `Token ${this.authState.getStateSnapshot().token}`
          },
          responseType: 'blob'
        }
      )

      if (response.success === false) return Result.fail(response.error)

      return Result.ok(response.response)
    } catch (error) {
      return Result.fail(error)
    }
  }

  async importWaypoints(file: File): Promise<Result<{ isAllValid: boolean }>> {
    try {
      const formData = new FormData()
      formData.append('file', file)

      const response = await this.httpCLient.post<RequestGetWaypointsResponse>(
        `${this.getRemoteUrl()}/importFile`,
        formData,
        {
          headers: {
            Authorization: `Token ${this.authState.getStateSnapshot().token}`,
            'Content-Type': 'multipart/form-data'
          }
        }
      )

      if (response.success === false) return Result.fail(response.error)

      let isAllValid = true

      this.userWaypoints = response.response.waypoints
        .map((remoteWaypoint) => {
          const { id, name, latitude, longitude, created_at, updated_at } = remoteWaypoint

          if (!latitude || !longitude) {
            isAllValid = false
            return null
          }

          return CoordWaypoint.create(
            {
              customName: String(name).trim(),
              coordinates: Coordinates.createFromArray([longitude, latitude]),
              createdAt: new Date(created_at),
              updatedAt: new Date(updated_at)
            },
            {
              isUserWaypoint: true
            },
            new UniqueEntityID(id)
          )
        })
        .filter((w) => w !== null) as CoordWaypoint[]

      return Result.ok({
        isAllValid
      })
    } catch (error) {
      return Result.fail(error)
    }
  }
}
